import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        
 <path d="M71.4586 7.26915e-06L1 0C0.447715 0 0 0.447713 0 0.999998L1.47258e-05 82.8871C1.50202e-05 84.544 1.34316 85.8871 3.00001 85.8871H28.5645C30.2214 85.8871 31.5732 84.5318 31.4837 82.8774C30.463 64.0248 20.4254 59.5 38.5 59.5C56.3859 59.5 38.8967 73.4142 40.4843 83.9598C40.6648 85.1589 41.8065 85.8871 43.019 85.8871H72C73.6569 85.8871 75.0046 84.5541 75.0399 82.8976C75.3226 69.6449 76.5633 44.1532 60.0161 44.1532C44.0161 44.1532 47 40.5 52.0161 39.6532C56.1479 38.9558 61.27 39.4073 66.5758 34.1934C69.1228 31.6905 70.3038 28.1775 70.8908 24.655L74.4178 3.4932C74.7226 1.66461 73.3124 7.45773e-06 71.4586 7.26915e-06Z" fill="var(--green)"/>
<path d="M24 27.2194V20C24 18.3431 25.3431 17 27 17H35.9825C39.0391 17 41.2819 19.8725 40.5405 22.8378C39.2281 26.532 35.7323 29 31.8119 29H25.7806C24.7972 29 24 28.2028 24 27.2194Z" fill="#0A192F"/>


      </g>
    </g>
  </svg>
);

export default IconLogo;
