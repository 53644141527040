import React from 'react';

const IconSketchfab = () => (
    <svg width="685" height="654" viewBox="0 0 685 654" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M360.797 337.456V584.158C360.797 585.384 362.081 586.185 363.182 585.646L570.001 484.437C570.57 484.159 570.93 483.581 570.93 482.948V231.847C570.93 230.609 569.622 229.808 568.519 230.371L361.7 335.98C361.146 336.264 360.797 336.834 360.797 337.456Z" stroke="black" strokeWidth="9"/>
<path d="M307.608 335.975V584.132C307.608 584.872 306.833 585.355 306.168 585.03L98.0346 483.178C97.6916 483.01 97.4741 482.661 97.4741 482.279V229.694C97.4741 228.947 98.2635 228.463 98.9289 228.803L307.063 335.084C307.397 335.255 307.608 335.599 307.608 335.975Z" stroke="black" strokeWidth="9"/>
<path d="M562.802 184.936L334.658 71.8025C334.2 71.5756 333.663 71.5723 333.203 71.7937L97.962 184.927C96.7337 185.518 96.7038 187.256 97.9112 187.889L333.151 311.134C333.64 311.39 334.223 311.386 334.708 311.124L562.853 187.878C564.036 187.24 564.006 185.533 562.802 184.936Z" stroke="black" strokeWidth="9"/>
</svg>

);

export default IconSketchfab;
