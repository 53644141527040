import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
     

      <path
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M71.4586 7.26915e-06L1 0C0.447715 0 0 0.447713 0 0.999998L1.47258e-05 82.8871C1.50202e-05 84.544 1.34316 85.8871 3.00001 85.8871H28.5645C30.2214 85.8871 31.5732 84.5318 31.4837 82.8774C31.1502 76.7178 29.8542 72.0877 28.8906 68.6452C26.9049 61.5511 26.3308 59.5 38.5 59.5C49.6252 59.5 47.0639 64.8834 43.9201 71.4909C42.0097 75.5063 39.8842 79.9737 40.4843 83.9598C40.6648 85.1589 41.8065 85.8871 43.019 85.8871H63.5081H72C73.6569 85.8871 75.0046 84.5541 75.0399 82.8976C75.0506 82.3981 75.0626 81.8812 75.075 81.349C75.3909 67.7595 75.9396 44.1532 60.0161 44.1532C44.0161 44.1532 47 40.5 52.0161 39.6532C52.7706 39.5259 53.5581 39.4368 54.3737 39.3446C58.0247 38.9318 62.2389 38.4552 66.5758 34.1934C69.1228 31.6905 70.3038 28.1775 70.8908 24.655L74.4178 3.4932C74.7226 1.66461 73.3124 7.45773e-06 71.4586 7.26915e-06ZM24 20V27.2194C24 28.2028 24.7972 29 25.7806 29H31.8119C35.7323 29 39.2281 26.532 40.5405 22.8378C41.2819 19.8725 39.0391 17 35.9825 17H27C25.3431 17 24 18.3431 24 20Z"

      />

       </g>
  </svg>
);

export default IconLoader;
